<template lang="html">
  <div class="drinks">
    <transition-group @before-enter="beforeEnter" @enter="enter" mode="out-in">
      <div
        class="drinks__item"
        :class="{
          'drinks__item--lock':
            drink['qr_code'] && !checkQrCode(drink['qr_code']),
        }"
        :key="drink.id"
        v-for="drink in getDrinks"
        @click="goToDrink(drink)"
      >
        <div class="drinks__item-image">
          <img
            v-if="drink['thumbnail_image']"
            :src="drink['thumbnail_image']['sizes']['medium_large']"
            :alt="drink['thumbnail_image']['alt']"
          />
          <div class="drinks__image-dummy" v-else>
            <DummyImage />
          </div>

          <img
            v-if="drink['qr_code'] && !checkQrCode(drink['qr_code'])"
            class="drinks__item-lock"
            src="@/assets/images/lock.svg"
            alt="lock"
          />
        </div>
      </div>
    </transition-group>

    <ModalQrCheck :show="showModal" @close-modal="closeModal" />
    <ModalQrFailure
      :show="modalQrFailureIsShow"
      @close-modal="closeModalQrFailure"
    />
    <QrCodeCamera
      :show="showQrCodeCamera"
      @read-code="readCodeFromCamera"
      @close-modal="closeQrModal"
    />
  </div>
</template>

<script>
import QrCodeCamera from '@/components/QrCodeCamera';
import ModalQrCheck from '@/components/modals/ModalQrCheck';
import ModalQrFailure from '@/components/modals/ModalQrFailure';
import DummyImage from '@/components/DummyImage';

export default {
  name: 'Drinks',
  components: {
    DummyImage,
    ModalQrFailure,
    ModalQrCheck,
    QrCodeCamera,
  },
  data() {
    return {
      idx: 0,
      showModal: false,
      showQrCodeCamera: false,
      modalQrFailureIsShow: false,
      selectedQr: '',
      selectedDrink: '',
    };
  },
  computed: {
    appData() {
      return this.$store.state.cookies.appData;
    },
    getDrinks() {
      return this.$store.state.cpt.drinks;
    },
    readQrCodes() {
      return this.$store.state.cookies.appData.readQrCodes;
    },
  },
  created() {
    this.$store.dispatch('cpt/fetchDrinks');
  },
  beforeUnmount() {
    this.$store.commit('cpt/setDrinks', []);
  },
  methods: {
    goToDrink(drink) {
      if (drink['qr_code'] && !this.checkQrCode(drink['qr_code'])) {
        this.selectedQr = drink['qr_code'];
        this.selectedDrink = drink.id;
        this.openModal();
      } else {
        this.$store.commit('routing/setCurrentRoute', 'single-post');
        this.$store.commit('routing/setSinglePostData', {
          postType: 'drinks',
          postId: String(drink.id),
        });
      }
    },
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transition = 'opacity 1s ease';
      el.style.transitionDelay = this.idx * 0.15 + 's';
      this.idx++;
    },
    enter(el) {
      el.style.opacity = 1;
    },
    checkQrCode(code) {
      if (!this.readQrCodes || !this.readQrCodes.length) return false;
      return this.readQrCodes.indexOf(code) !== -1;
    },
    setCode(id) {
      const codes = [...this.readQrCodes];
      codes.push(id);

      this.$store.commit('cookies/saveAppData', {
        key: 'readQrCodes',
        value: codes,
      });
    },
    openModal() {
      if (this.appData.modalQrHelpIsShow) {
        this.showQrCodeCamera = true;
      } else {
        this.showModal = true;
      }
    },
    closeModal() {
      this.showModal = false;
      this.showQrCodeCamera = true;
      this.$store.commit('cookies/saveAppData', {
        key: 'modalQrHelpIsShow',
        value: true,
      });
    },
    closeModalQrFailure() {
      this.modalQrFailureIsShow = false;
    },
    closeQrModal() {
      this.showQrCodeCamera = false;
    },
    readCodeFromCamera(e) {
      if (e === this.selectedQr) {
        this.setCode(e);
        this.showQrCodeCamera = false;
        this.$store.commit('routing/setCurrentRoute', 'single-post');
        this.$store.commit('routing/setSinglePostData', {
          postType: 'drinks',
          postId: String(this.selectedDrink),
        });
      } else {
        if (this.modalQrFailureIsShow) return;
        this.closeQrModal();
        this.modalQrFailureIsShow = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.drinks {
  margin: 0 -10px;
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: calc(50% - 20px);
    margin: 0 10px;

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    &-image {
      position: relative;
    }

    &--lock {
      .drinks__item-image {
        &:after {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.7),
            rgba(0, 0, 0, 0.7)
          );
        }
      }
    }

    &-lock {
      position: absolute;
      width: 42px;
      height: 56px;
      right: -14px;
      bottom: -7px;
      z-index: 1;
    }
  }

  &__image-dummy {
    height: 0;
    padding-bottom: 100%;
    position: relative;
    :deep(.dummy-image) {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
.image {
  width: 100%;
  display: flex;
  justify-content: center;

  img {
    max-width: 100%;
    display: block;
  }
}
</style>
